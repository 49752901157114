body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
  }
  
  .container {
    text-align: center;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .output-url {
    margin-top: 20px;
    font-size: 18px;
  }
  
  .output-url a {
    color: #007bff;
    text-decoration: none;
  }
  
  .output-url span {
    margin-left: 5px;
    cursor: pointer;
  }
  